body{
  background: #eee !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Login-body-wrapper {
    min-height: 100vh;
    padding-bottom: 40px;
}

.bg-image img {
    position: absolute;
    height: 285px;
    width: 100%;
    z-index: -1;
}

/* .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
} */

.header-section {
    padding: 12px 0px;
    size: 500px;
    text-align: center;
}

.header-section img {
    max-height: 60px;
}

.top-message-text {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    line-height: 38px;
    margin: 25px 0px;
}

.store-add-card {
    margin-bottom: 24px;
}

.login-forn-content-addstore {
    padding: 50px;
    border: 10px;
    background:#fff;
    border-radius: 10px;
    margin-bottom: 20px;
    min-height: calc(100vh - 240px) !important;
}

.login-page-footer-text {
  margin-top: 50px;
  padding: 5px 15px;
  -webkit-box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  z-index: 1020;
  display: flow-root;
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.login-page-footer-text {
  padding: 5px 15px;
  -webkit-box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  z-index: 1020;
  display: flow-root;
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.login-page-footer-text {
  padding: 5px 15px;
  -webkit-box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  z-index: 1020;
  display: flow-root;
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.login-page-footer-text p {
  font-size: 12px;
  color: #000;
  margin-bottom: 2px;
  padding: 5px 0px;
  font-weight: 600;
}
.login-page-footer-text p {
  font-size: 12px;
  color: #000;
  margin-bottom: 2px;
  padding: 5px 0px;
  font-weight: 600;
}
.login-page-footer-text p {
  font-size: 12px;
  color: #000;
  margin-bottom: 2px;
  padding: 5px 0px;
  font-weight: 600;
}
.float-left {
  float: left!important;
}
.reset-model-header h2, p {
  color: #000;
  font-weight: 600;
}

.float-right {
  float: right!important;
}

.obsoulteBg {
  position: absolute;
  background: #fff;
  height: 37px;
  width: 250px;
  top: 122px;
  left: 50%;
  transform: translate(-50%, 0px);
}